<template>
  <!-- VERSÃO DESKTOP -->
  <div class="login flex row justify-end" v-if="!isMobile" style="height: 100vh; width: 100vw">
    <div class="formBG col-6 flex justify-end">
      <div class="q-mt-md">
        <div class="titles">
          <div class="logo q-mt-xl">
            <q-img
              class="q-my-xl"
              src="../../assets/logoFatureSistemas.png"
              width="150px"
            />
          </div>
          <div class="text-left text-bold text-h6 text-white" style="width: 380px">
            Acessar área Administrativa:
          </div>
        </div>
        <form>
          <div class="inputs col">
            <q-input
              outlined
              class="q-my-md"
              bg-color="white"
              color="verde"
              v-model="mail"
              type="email"
              :label="labelMail"
            />
            <q-input
              outlined
              class="q-my-md"
              color="verde"
              bg-color="white"
              v-model="pass"
              :type="isPwd ? 'password' : 'text'"
              :label="labelPass"
              @keypress.enter="onAccess"
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility' : 'visibility_off'"
                  @click="isPwd = !isPwd"
                />
              </template>
            </q-input>
          </div>
          <div class="btns row">
            <q-btn glossy class="full-width" outline color="white" label="Acessar" @click="onAccess" />
            <!-- <q-btn class="col-6" flat color="white" label="Esqueci a senha" @click="onRemmenber" /> -->
            <!-- <q-btn class="col-6" flat color="white" label="Esqueci a senha" @click="onShowRemmenber" /> -->
          </div>
        </form>

        <div class="row justify-center">
          <q-btn
            v-show="newUser"
            no-caps
            flat
            class="newUser text-h5 text-bold"
            text-color="yellow"
            label="Novo Usuário"
            @click="onNewUser"
          />
        </div>

        <div class="suporte">
          <q-avatar
            class="cursor-pointer"
            size="50px"
            font-size="40px"
            text-color="green"
            icon="fab fa-whatsapp"
            @click="onRedirectWhats"
          />
          <q-chip
            class="bg-green-3"
            icon="person"
            label="Posso te ajudar?"
            v-model="msgWhats"
            @click="onRedirectWhats"
            removable
            @remove="msgWhats = false"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- VERSÃO MOBILE -->
  <div class="login flex column justify-end" v-else style="height: 100vh; width: 100vw">
    <div class="img col-1 bg-verde">
      <div class="image" style="z-index: 1;">
        <q-img
          src="../../assets/bgLoginCATALOGO.jpg"
          height="100vh"
        />
      </div>
    </div>
    <div class="form col-11 flex justify-center">
      <div class="q-mt-xl q-pt-xl" style="z-index: 2;">
        <div class="bgColorFormMobile q-pa-md">
          <div class="title text-white text-bold text-h3 q-mb-md" style="font-family: 'Gemunu Libre'">
            <div class="row justify-center">
                <q-img
                  src="../../assets/logoFatureSistemas.png"
                  height="auto"
                  width="120px"
                />
            </div>
          </div>
          <div class="inputs col" style="width: 80vw">
            <q-input
              outlined
              class="q-my-md"
              bg-color="white"
              color="verde"
              v-model="mail"
              type="email"
              :label="labelMail"
            />
            <q-input
              outlined
              class="q-my-md"
              color="verde"
              bg-color="white"
              v-model="pass"
              :type="isPwd ? 'password' : 'text'"
              :label="labelPass"
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility' : 'visibility_off'"
                  @click="isPwd = !isPwd"
                />
              </template>
            </q-input>
          </div>
          <div class="btns row">
            <q-btn glossy class="full-width text-black" color="white" label="Acessar" @click="onAccess" />
            <!-- <q-btn class="col-6" flat color="white" label="Esqueci a senha" @click="onShowRemmenber" /> -->
          </div>
        </div>

        <div class="suporte">
          <q-avatar
            size="50px"
            font-size="40px"
            text-color="green"
            icon="fab fa-whatsapp"
            @click="onRedirectWhats"
          />
          <q-chip
            class="bg-green-3"
            icon="person"
            label="Posso te ajudar?"
            v-model="msgWhats"
            @click="onRedirectWhats"
            removable
            @remove="msgWhats = false"
          />
        </div>
      </div>
    </div>
  </div>

    <!-- MODAL PARA COMPLETAR CADASTRO HOTMART -->
  <q-dialog v-model="modalNEW" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div class="text-h5 text-bold q-mt-lg">
          Seja bem vindo
        </div>
        <div>
          Por favor complete seu cadastro com as seguintes informações:
        </div>
      </q-card-section>
      <q-card-section>
        <div class="text-subtitle2 q-mt-md">Informe seu Nome Completo</div>
        <q-input
          outlined
          dense
          autofocus
          v-model="fullName"
          type="text"
        />

        <div class="text-subtitle2 q-mt-md">Informe seu telefone de Contato</div>
        <q-input
          outlined
          dense
          v-model="phoneContact"
          mask="(##) # ####-####"
        />

        <div class="text-subtitle2 q-mt-md">Informe o Nome da sua Empresa</div>
        <q-input
          outlined
          dense
          v-model="nameEnterprise"
          type="text"
        />
      </q-card-section>

      <q-card-actions class="justify-between q-px-xl q-mb-xl q-mt-md">
        <q-btn outline glossy dense style="width: 45%" color="negative" label="Cancelar" v-close-popup />
        <q-btn outline glossy dense style="width: 45%" color="positive" label="Finalizar" @click="onFinish" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>

</template>

<script>
import { Dialog } from 'quasar';
import { supabase } from '../../supabase';
import Slug from '../../helpers/Slug';

export default {
  data() {
    return {
      mail: '',
      pass: '',
      labelMail: 'E-Mail',
      labelPass: 'Senha',
      isPwd: true,
      modalPWD: false,
      newAccess: false,
      modalNEW: false,
      fullName: null,
      phoneContact: null,
      nameEnterprise: null,
      msgWhats: true,
      nameResponsible: null,
      newUser: false,
    }
  },
  computed: {
    isMobile() {
      return (this.$q.screen.sm || this.$q.screen.xs);
    }
  },
  methods: {
    async onAccess() {
      //~> PARA LIBERAÇÃO DO RECURSO DE NOVO USUÁRIO
      if(this.mail === 'novo' && this.pass === 'novo') {
        this.newUser = true;

        this.mail = '';
        this.pass = 'faturecatalogo';
        this.labelMail = 'Novo E-Mail';
        this.labelPass = 'Gerar Senha';
        this.isPwd = false;
        return;
      } else {
        this.newUser = false;
      }

      try {
        // VALIDAÇÃO DO PREENCHIMENTO DOS CAMPOS
        if(
          this.mail.indexOf('@') <= 0 ||
          this.mail.indexOf('.') <= 0 ||
          this.mail === '' ||
          this.mail === null
        ) {
          throw new Error('Favor verificar seu E-Mail.');
        }

        if(
          this.pass.length < 6 ||
          this.pass === '' ||
          this.pass === null
        ) {
          throw new Error('Favor verificar seu E-Mail e/ou Senha.');
        }

        try {
          const { error } = await supabase.auth.signIn({
            email: String(this.mail).toLowerCase(),
            password: this.pass
          });

          if(error) {
            throw new Error('Favor verificar seu E-Mail e/ou Senha.');
          } else {
            let infos = await supabase
              .from('GE_PERFIL_USERS')
              .select('GE_EMAIL, GE_ENTERPRISE, GE_DEFAULTCOLOR, RECNO, DELETED')
              .match({'GE_EMAIL': String(this.mail).toLowerCase()})
              .single()

            if(infos.data.DELETED === '*') {
              Dialog.create({
                title: 'Fature Catálogo',
                message: 'Usuário bloqueado, para mais detalhes, favor entrar em contato com o administrador do Sistema!'
              });

              return null;
            }

            //~> Para coletar informações complementares quando novo acesso via HotMart
            if(infos.data.GE_ENTERPRISE === 'COLETAR DEPOIS') {
              this.modalNEW = true
              this.newAccess = true

              return null;
            }

            localStorage.enterprise = infos.data.GE_ENTERPRISE;
            localStorage.defaultColor = infos.data.GE_DEFAULTCOLOR;
            localStorage.recnoClient = infos.data.RECNO;

            this.$router.push('/settings');
          }
        } catch (e) {
          Dialog.create({
            title: 'Fature Catálogo',
            message: 'Favor verificar seu E-Mail e/ou Senha.' //e.message|| e.error_description
          });

          return;
        }
  
        
      } catch (err) {
        Dialog.create({
          title: 'Fature Catálogo',
          message: 'Favor verificar seu E-Mail e/ou Senha.' //err.message|| err.error_description
        });

        return;
      }
    },
    onShowRemmenber() {
      this.modalPWD = true;
    },
    async onRemmenber() {
      // TODO: Fazer o sistema de reset da senha

      let { data } = await supabase.auth.signUp({
        email: 'config@gestaoesportes.com.br',
        password: '@dmin2022...VamorRumoAVitória'
      })

      console.log(data);
    },
    getAccess() {
      let dt = new Date()
      let dia = (dt.getDate()).toString()
      let mes = (dt.getMonth() + 1 ).toString()
      let ano = (dt.getFullYear()).toString()
      let hor = (dt.getHours()).toString()
      let min = (dt.getMinutes()).toString()

      let data = String(0).repeat(2 - dia.length) + dia + '/' + String(0).repeat(2 - mes.length) + mes + '/' + ano
      let hora = String(0).repeat(2 - hor.length) + hor + ':' + String(0).repeat(2 - min.length) + min

      // console.log(data)
      return {data: data, hora: hora}

    },
    onTutor() {
      this.$router.push('/tutoriais')
    },
    async onFinish() {
      const slug = Slug(this.nameEnterprise)

      const { data } = await supabase
        .from('GE_PERFIL_USERS')
        .select('*')
        .eq('GE_ENTERPRISE', this.nameEnterprise)

      if(data.length > 0) {
        Dialog.create({
          title: 'Fature Catálogo',
          message: 'Já existe uma empresa cadastrada com este nome!',
          cancel: 'Esolher outro Nome',
          ok: false
        })
      } else {
        const Settings = await supabase
          .from('GE_SETTINGS')
          .update({
            'GE_ENTERPRISE': this.nameEnterprise,
            'GE_SLUG': slug
          })
          .match({
            'GE_EMAIL': this.mail
          })

        const Perfil = await supabase
          .from('GE_PERFIL_USERS')
          .update({
            'GE_ENTERPRISE': this.nameEnterprise,
            'GE_RESPONSIBLE': this.fullName,
            'GE_PHONE': this.phoneContact
          })
          .match({
            'GE_EMAIL': this.mail
          })

        if(Settings.error || Perfil.error) {
          Dialog.create({
            title: 'Fature Catálogo',
            message: 'Houve um problema na gravação dos dados, por favor repetir o processo!',
            cancel: false,
            ok: true
          }).onOk(() => {
            return ''
          })
        }
      }
    },
    async onNewUser() {

      if (this.mail === '' || !this.mail.includes('@') || !this.mail.includes('.com'))
        return Dialog.create({
          title: 'Fature Catálogo',
          message: `<div class="text-bold">Favor preencher com Email válido</div>`,
          html: true,
          ok: false,
          cancel: 'Fechar'
        });

      if (this.pass.length < 6)
        return Dialog.create({
          title: 'Fature Catálogo',
          message: `<div class="text-bold">A senha deve conter no mínimo 6 dígitos</div>`,
          html: true,
          ok: false,
          cancel: 'Fechar'
        });

      this.nameEnterprise = prompt('Nome da Empresa')
      this.fullName = prompt('Nome do Responsável pela Empresa')
      this.phoneContact = prompt('Telefone de Contato')

      if(
        this.nameEnterprise === null || this.nameEnterprise === '' ||
        this.fullName === null || this.fullName === '' ||
        this.phoneContact === null || this.phoneContact === ''
      ) {
        Dialog.create({
          title: 'Fature Catálogo',
          message: `<div class="text-red text-bold">Erro ao registrar novo usuário.</div>
                    <br/>
                    <div class="text-bold">Todos os campos devem ser preenchidos</div>`,
          html: true,
          ok: false,
          cancel: 'Fechar'
        })

        return;
      }

      const { user, error } = await supabase.auth.signUp({
        email: this.mail,
        password: this.pass
      })

      const { data: perfil } = await supabase
        .from('GE_PERFIL_USERS')
        .insert({
          GE_EMAIL: this.mail,
          GE_ENTERPRISE: this.nameEnterprise,
          GE_DEFAULTCOLOR: 'default',
          GE_DUEDATE: 1,
          GE_FREQUENCY: 'Mensal',
          GE_PAYMETHOD: 'NA',
          GE_RESPONSIBLE: this.fullName,
          GE_PHONE: this.phoneContact,
          DELETED: ' '
        })

      const { data: settings } = await supabase
        .from('GE_SETTINGS')
        .insert({
          GE_ENTERPRISE: this.nameEnterprise,
          DELETED: ' ',
          GE_SLUG: Slug(this.nameEnterprise),
          GE_EMAIL: this.mail,
          GE_CATEGORYS: []
        })

      if(!perfil || !settings)
        Dialog.create({
          title: 'Fature Catálogo',
          message: 'Erro ao incluir novo usuário!',
          ok: false,
          cancel: 'Fechar'
        })

      console.log(user);
      console.log(error);
    },
    onRedirectWhats() {
      window.open(
        'https://api.whatsapp.com/send?phone=5532984293724&text=Olá, gostaria de ajuda com o Sistema Fature Catálogo!',
        '_blank'
      );
    }
  },
}
</script>

<style lang="scss" scoped>

  .login {
    background-image: url('../../assets/bgLoginCATALOGO.jpg');
    background-size: auto 100vh;
  }

  .bgColorFormMobile {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 15px;
    border: 0.5px solid #ff5900;
    box-shadow: 0px 0px 30px #ff5900;
  }

  .formBG {
    background: linear-gradient(90deg, transparent 0%, black 15%);
    padding-right: calc((50% - 450px) / 2);
  }

  .tutor {
    position: absolute;
    bottom: 0;
  }

  .newUser {
    font-family: 'Gemunu';
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .suporte {
    position: fixed;
    left: 10px;
    top: 5px;
  }

  @import url('https://fonts.googleapis.com/css2?family=Gemunu+Libre&display=swap');
</style>
